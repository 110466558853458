import React, { useEffect, useRef, useState } from 'react';
import {
    ZoomableGroup,
    ComposableMap,
    Geographies,
    Geography
} from "react-simple-maps";
import { Button, ButtonGroup, Col, Input, Row, UncontrolledTooltip } from 'reactstrap';
import Card from '../../components/ui/Card/Card';
import WorldMap from '../../components/ui/Map/WorldMap';
import { GetSupplierRatings, Suppliers } from '../../data/suppliers';
import Countries from '../../data/countries';
import LoaderContainer from '../../components/ui/LoaderContainer/LoaderContainer';
import { useAuth } from '../../contexts/authContext';
import DataTable from '../../components/ui/Table/DataTable';
import SelectFilter from '@inovua/reactdatagrid-enterprise/SelectFilter'
import DateFilter from '@inovua/reactdatagrid-enterprise/DateFilter'
import moment from 'moment';
import { reportingPosture } from '../../data/reports';
import LineChart from '../../components/ui/Charts/Line/Line';
import DCSBarChart from '../../components/ui/Charts/Bars/Bars';
import RatingCalculator, { PostureRatingSupplier } from '../../utils/ratingCalculator';
import { getRatings } from '../../data/administration';
import Modal from '../../components/ui/Modal/Modal';
import ReportPDF from '../../components/ui/reportPDF/reportPDF';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from 'react-select';
import { faExclamationCircle, faFilePdf, faFolderPlus, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import SupplierFilters from '../../components/ui/Helpers/SupplierFilters';



const Reports = (props) => {
    const Title = "Reports";
    const [suppliers, setSuppliers] = useState(null);
    const [target, setTarget] = useState(undefined);
    const [countries, setCountries] = useState([]);
    const [ratingSource, setRatingSource] = useState(null);
    const [supplierCyberPosture, setSupplierCyberPosture] = useState(null);
    const [content, setContent] = useState("");
    const [geoData, setGeoData] = useState(null);
    const [filters, setFilters] = useState(null);
    const [supplierFilter, setSupplierFilter] = useState(null);
    const [assets, setAssets] = useState([]);
    const [postureSource, setPostureSource] = useState(null);
    const [formula, setFormula] = useState(null);
    const auth = useAuth();

    const pClasses = [
        { id: 0, pill: "pGray", rating: "N/A" },
        { id: 1, pill: "plGreen", rating: "Great" },
        { id: 2, pill: "pdGreener", rating: "Good" },
        { id: 3, pill: "pOrange", rating: "Bad" },
        { id: 4, pill: "pRed", rating: "Critical" }
    ];
    const filedFileds = {
        SupplierName: '',
        Country: countries.map(x => x.Name),
        DateCreated: '',
        enabled: true
    }
    const generateDates = () => {
        const today = moment();
        const sixMonthsAgo = moment().subtract(6, 'months');

        const dates = [];
        let currentDate = today;

        while (currentDate.isSameOrAfter(sixMonthsAgo, 'month')) {
            dates.push(currentDate.startOf('month').format('YYYY-MM'));
            currentDate = currentDate.subtract(1, 'month');
        }

        return dates.reverse();
    };
    useEffect(() => {
        window.moment = moment;
        Countries().then(c => {
            setCountries(c.data);
        })
        getRatings(auth.token, auth.user.ClientId).then(rez => {
            if (rez.data != undefined) {
                setFormula(rez.data);
            }
        })

    }, []);
    const isDateInRange = (date, start, end) => {
        //console.log(date);
        const targetDate = moment(moment(date).format("YYYY-MM-DD"));
        //console.log(targetDate);
        const startDate = moment(start, 'YYYY-MM');
        const endDate = moment(end, 'YYYY-MM').endOf('month');
        return targetDate.isBetween(startDate, endDate, 'day', '[]');
    };

    useEffect(() => {
        if (countries.length > 0) {
            GetSupplierRatings(auth.user.ClientId, 6).then((result) => {
                setSuppliers(result.data);
            })
        }
    }, [countries])
    useEffect(() => {
        if (suppliers != null) {
            let result = [];
            let sdrop = [];
            let frating = [];
            let scposture = [];
            let SAssets = [];
            let FAssets = [];

            suppliers.Rating.forEach((x, index, arr) => {

                let posture = 0;
                x.SupplierRatings.filter(p => supplierFilter != null ? p.Supplier.Id == supplierFilter.value : true).forEach((z, indexx, arrr) => {
                    if (z.Rating.Rating > 0) {
                        posture += z.PostureRating < 0 ? 0 : z.PostureRating;
                    }
                    if (SAssets.filter(p => p.Supplier.Name == z.Supplier.Name).length == 0) {
                        sdrop.push({ label: z.Supplier.Name, value: z.Supplier.Id });
                        scposture.push({
                            name: z.Supplier.Name,
                            label: z.PostureRating != -1 ? pClasses[z.Rating.PostureRating].rating : pClasses[0].rating,
                            value: z.PostureRating != -1 ? Math.round(z.PostureRating).toFixed(0) : 0
                        });
                        SAssets.push(z);
                    }
                });


                //SAssets = [...new Set(SAssets)];
                //console.log(SAssets);
                const nrSup = x.SupplierRatings.filter(r => r.Rating.Rating > 0 && (supplierFilter != null ? r.Supplier.Id == supplierFilter.value : true)).length;
                posture = nrSup > 0 ? Math.round(posture / nrSup).toFixed(0) : 0;
                if (posture < 0) {
                    posture = 0;
                }
                frating.push({
                    name: moment().add((index * -1), "M").endOf('month').format("YYYY-MM-DD"),
                    label: pClasses[supplierFilter == null ? x.Rating : x.SupplierRatings.filter(z => z.Supplier.Id == supplierFilter.value)[0].Rating.Rating].rating,
                    value: supplierFilter == null ? x.Score : x.SupplierRatings.filter(z => z.Supplier.Id == supplierFilter.value)[0].Rating.Score
                })
                result.push({
                    name: moment().add((index * -1), "M").endOf('month').format("YYYY-MM-DD"),
                    label: pClasses[supplierFilter == null ? x.Rating : x.SupplierRatings.filter(z => z.Supplier.Id == supplierFilter.value)[0].Rating.PostureRating].rating,
                    value: posture
                })
            });
            if (filters == null) {
                setFilters(sdrop);
            }
            FAssets = SAssets.map(x => { const Sname = x.Supplier.Name; return x.AssetsGeo.map(z => { return { Supplier: Sname, Name: z.Name, CountryId: z.CountryId, DateCreated: z.DateCreated } }) }).flat();
            setAssets(FAssets);
            //console.log(SAssets);
            getGeo(FAssets, countries);
            setSupplierCyberPosture(scposture);
            setRatingSource(frating.reverse());
            setPostureSource(result.reverse());
        }
    }, [suppliers, supplierFilter])
    const getGeo = (data, countries) => {

        const l = data.map(x => {

            const ct = countries.filter(q => q.Id == x.CountryId);
            if (ct.length > 0) {
                return ct[0].Iso3;
            }
            else
                return "";
        })
        setGeoData(l.flat().filter(x => x != ""));
    }

    const contriesFilterd = assets.reduce((contriesFilterd, p) => {

        if (contriesFilterd.filter(c => c.id == p.CountryId).length > 0) {
            return contriesFilterd
        }

        contriesFilterd.push({
            id: p.CountryId,
            label: countries.filter(z => z.Id == p.CountryId)[0]?.Name
        })
        return contriesFilterd.filter(x => x.id != undefined);
    }, [])

    const addToolTip = () => {
        if (target != undefined) {

            const id = target.split("-");
            return (<UncontrolledTooltip key={target} trigger={"hover"} defaultOpen={false} target={`geo-${id[1]}`}>{content}</UncontrolledTooltip>);
        }
    }

    const filterClassname = 'filter-cell form-control';
    const columns = [
        {
            name: 'Supplier',
            header: 'Supplier Name',
            sortable: true,
            headerCellClass: 'dcs-sort',
            render: ({ value }) => {
                return <>{value}</>
            }
        },
        {
            name: 'Name',
            header: 'Asset',
            sortable: true,
            headerCellClass: 'dcs-sort',
            render: ({ value }) => {
                return <>{value}</>
            }
        },
        {
            name: 'CountryId',
            header: 'Country',
            sortable: true,
            headerCellClass: 'dcs-sort',
            filterEditor: SelectFilter,
            filterEditorProps: {
                placeholder: 'All',
                dataSource: contriesFilterd
            },
            render: ({ value }) => {
                return <>{countries.filter(x => x.Id == value)[0]?.Name}</>
            }
        },
        {
            header: 'Date Created',
            name: 'DateCreated',
            sortable: true,
            filterEditor: DateFilter,
            filterEditorProps: (props, { index }) => {
                // for range and notinrange operators, the index is 1 for the after field
                return {
                    dateFormat: 'MM-DD-YYYY',
                    cancelButton: true,
                    highlightWeekends: false,
                    placeholder: index == 1 ? 'Created date is before...' : 'Created date is after...'
                }
            },
            render: ({ value }) => {
                return moment(value).format('MM-DD-YYYY')
            }
        }
    ];
    const initialSupplierSort = [{ columnKey: 'id', direction: 'ASC' }];

    const filterValue = [
        { name: 'Name', operator: 'startsWith', type: 'string', value: '' },
        { name: 'Supplier', operator: 'startsWith', type: 'string', value: '' },
        { name: 'CountryId', operator: 'eq', type: 'select', value: null },
        {
            name: 'DateCreated',
            operator: 'before',
            type: 'date',
            value: ''
        },

    ];
    const [showReportPdf, setShowPdf] = useState(false);
    const fref = useRef();
    const Export = () => {
        setShowPdf(true);
    }
    const FilterRef = useRef(null);

    const dropdownStile = {
        control: (styles) => ({ ...styles, backgroundColor: '#FFF' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isDisabled ? "#1861ac" : isSelected ? "#1861ac" : isFocused ? "#1861ac" : undefined,
                color: isDisabled ? undefined : isSelected ? "#FFF" : isFocused ? "#FFFFFF" : "#1861ac",
            }
        },
        placeholder: (styles) => ({ ...styles, color: "#999" }),
        singleValue: (styles, { data }) => ({ ...styles, backgroundColor: "#FFF", color: "#1D56A3" })
    }

    return (<>
        {suppliers != null && showReportPdf && <Modal ref={FilterRef} title={"Preview"} callBack={() => { setShowPdf(false); }}>
            <ReportPDF suppliers={suppliers} filterValue={filterValue} />
        </Modal>}
        <Row className="white">
            <Col xs md="12" className="headerText">
                <h3 className="dcs-Title bold">{Title}</h3>
                <ButtonGroup> 
                    <Select
                        ref={fref}
                        styles={dropdownStile}
                        isDisabled={filters == null ? true : false}
                        options={filters}
                        onChange={(choice) => setSupplierFilter(choice)}
                        isClearable
                        isSearchable
                        placeholder="Select Supplier" />
                    <Button className='btn-primary' id="exportportfolio" size="xl" onClick={() => { Export() }}><FontAwesomeIcon icon={faFilePdf} /></Button>
                    <UncontrolledTooltip
                        placement="bottom"
                        target={"exportportfolio"}
                    >
                        Export report
                    </UncontrolledTooltip>
                </ButtonGroup>
            </Col>
        </Row>
        <Row>
            <Col md="12" xs="12">
                <Row className="white">
                    <Col xs md="12" className="headerText">
                        <h4 className="dcs-Title bold">{supplierFilter == null ? "General Risk Rating" : `General Risk Rating for ${supplierFilter.label}`}</h4>
                    </Col>
                </Row>
                <Card>
                    <Col md="12" xs="12">
                        {ratingSource != null ?
                            <LineChart data={ratingSource} height={5 / 1} subtitle={"Last 6 months (%)"} /> : <LoaderContainer />}
                    </Col>
                </Card>
            </Col>
            <Col md="6" xs="12">
                <Card>
                    {
                        suppliers != null ?
                            countries.length > 0 && geoData != null ? <>
                                <h5 className="dcs-Title bold">Geographical Asset Distribution</h5>
                                <WorldMap countries={geoData} setToolTipTarget={setTarget} setTooltipContent={setContent} />
                                {//addToolTip()

                                }
                            </> : null : <LoaderContainer />
                    }
                </Card>
            </Col>
            <Col md="6" xs="12" style={{ minHeight: "500px" }}>

                <Card>
                    {contriesFilterd != null ? geoData != null && countries.length > 0 ?
                        <DataTable showTitle={false} rowHeight={50} style={{ minHeight: "725px" }} remindHeight={500} defaultFilterValue={filterValue} enableColumnAutosize={true} defaultsortColumns={initialSupplierSort} showZebraRows={false} pagination rowSelect={false} limit={15} livePagination={false} title="Suppliers" data={assets} virtualizeColumns={false} showCellBorders={true} PageSize={15} columns={columns} /> : <LoaderContainer /> : <LoaderContainer />}
                </Card>

            </Col>

            <Col md="6" xs="12">
                <Row className="white">
                    <Col xs md="12" className="headerText">
                        <h4 className="dcs-Title bold">Cyber Posture Rating</h4>
                    </Col>
                </Row>
                <Card>
                    <Col md="12" xs="12">
                        {suppliers != null ?
                            <LineChart data={postureSource} subtitle={"Last 6 months (%)"} /> : <LoaderContainer />}
                    </Col>
                </Card>
            </Col>
            <Col md="6" xs="12">
                <Row className="white">
                    <Col xs md="12" className="headerText">
                        <h4 className="dcs-Title bold">Supplier Cyber Posture</h4>
                    </Col>
                </Row>
                <Card>
                    <Col md="12" xs="12">
                        {supplierCyberPosture != null && formula != null ?
                            <DCSBarChart data={supplierCyberPosture} subtitle={"Last 6 months (%)"} /> : <LoaderContainer />}
                    </Col>
                </Card>
            </Col>
            <Col md="6" xs="12">
                <Row className="white">
                    <Col xs md="12" className="headerText">
                        <h4 className="dcs-Title bold">Vulnerabilities chart</h4>
                    </Col>
                </Row>
                <Card>
                    <Col md="12" xs="12">
                        {postureSource != null ?
                            <DCSBarChart data={[]} subtitle={"Last 6 months (%)"} /> : <LoaderContainer />}
                    </Col>
                </Card>
            </Col>
        </Row>

    </>)
}
export default Reports;