import React, { useRef, useState, useEffect } from 'react';
import Modal from '../Modal/Modal';
import { GetContactsAPI } from '../../../data/contacts';
import { GetQuestionary, SendQuestionary } from '../../../data/questionary';
import Select from 'react-select';
import { Col, Row, Form, Label, Button, FormGroup, Alert } from 'reactstrap';
import LoaderContainer from '../LoaderContainer/LoaderContainer';
import DateTimePicker from 'react-datetime-picker';
import "./QuestionarySendModal.css";
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { useAuth } from '../../../contexts/authContext';
const QuestionarySendModal = (props) => {
    const { id, SupplierId, setShowModal, questionariesSent } = props;
    const CurrentDate = new Date();

    const ClientId = '';
    const [show, setShow] = useState(false);
    const [contacts, setContacts] = useState(null);
    const [isLoding, setIsLoading] = useState(null);
    const [forms, setForms] = useState(null);
    const [error, setError] = useState(null);
    const addWeeksToDate = (numberOfWeeks, currentDate) => {
        currentDate.setDate(currentDate.getDate() + numberOfWeeks * 7);
        return currentDate
    }

    const [value, setValue] = useState(CurrentDate);
    const formRef = useRef();
    const contactRef = useRef();
    const addModalRef = useRef();
    const dueDateRef = useRef();
    const auth = useAuth();
    const handleChange = (selectedDate) => {
        console.log(selectedDate);
        setShow(false);
    }
    const handleClose = (state) => {
        setShow(true);
    }


    useEffect(() => {
        addWeeksToDate(3, CurrentDate)
        setIsLoading(true);
        try {
            GetContactsAPI(null, SupplierId).then(rez => {
                setContacts(
                    rez.data.map(x => {
                        return { value: x.Id, label: x.Name }
                    }))
                //setContacts(rez.data);
            })
            GetQuestionary(null, auth.user.ClientId).then(rez => {
                setForms(
                    rez.data.map(x => {
                        return { value: x.Id, label: x.Name };
                    }));
                // setForms(rez.data);
            })
            setIsLoading(false);
        } catch (ex) {
            setIsLoading(false);
        }
    }, [])
    const SendForm = (e) => {
       
        e.preventDefault();
        formRef.current.getValue().map(x => {
            contactRef.current.getValue().map(y => {
                let existingq = questionariesSent.filter(p=>p.u.QuestionaryId==x.value && p.u.SupplierId==SupplierId).length > 0 ? true : false;
                console.log( questionariesSent.filter(x => x.u.QuestionaryId == x.value));
                if (!existingq) {
                    setError(null);
                    SendQuestionary(x.value, y.value, SupplierId, value, auth.user.ClientId).then(rez => {
                        //TODO add warning if didn't work
                        setShowModal(false);
                    }).catch(err=>{
                        setError("Could not complete request. Please contact system administrator");
                    })
                } else {
                    setError("One or more questionaries selected already sent or awaiting approval/rejection");
                    setSendEnabled(false);
                }
            })
        })
        // if (error==null) {
        //    // setShowModal(false);
        // }
    }
    const addContactFormRef = useRef();
    const [sendEnabled, setSendEnabled] = useState(false);
    useEffect(() => {
        if (formRef.current != undefined && contactRef.current != undefined && error == null) {
            if (contactRef.current.getValue().length > 0 && formRef.current.getValue().length > 0) {
                setSendEnabled(false);
            }
        }
    }, [contactRef.current, formRef.current])
    //Dropdown Styles
    const dropdownStile = {
        control: (styles) => ({ ...styles, backgroundColor: '#FFF' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isDisabled ? "#1861ac" : isSelected ? "#1861ac" : isFocused ? "#1861ac" : undefined,
                color: isDisabled ? undefined : isSelected ? "#FFF" : isFocused ? "#FFFFFF" : "#1861ac",
            }
        },
        placeholder: (styles) => ({ ...styles, color: "#999" }),
        singleValue: (styles, { data }) => ({ ...styles, backgroundColor: "#FFF", color: "#1D56A3" })
    }


    return (
        <>
            
            <Modal title="Send Questionary" ref={addModalRef} callBack={setShowModal}>
            {
                error != null ?
                    <Alert color="danger">{error}</Alert>
                    : null
            }
                <Col>
                    <Form className='row' ref={addContactFormRef} onSubmit={SendForm}>
                        <FormGroup>
                            <Label>
                                {contacts != null || !setIsLoading ?
                                    <>
                                        {contacts.length == 0 ? <span style={{ color: "#FF0000", width: "100%", display: "flex" }}>Please add contacts to this supplier</span> : null}
                                        <span>Contacts</span>
                                    </> : null}
                            </Label>
                            {contacts != null || !setIsLoading ?
                                < Select
                                    ref={contactRef}
                                    className='col-md-12'
                                    styles={dropdownStile}
                                    options={contacts}
                                    isMulti={true}
                                    required={true}
                                    isClearable
                                    isSearchable
                                    placeholder="Select Contact" /> : <LoaderContainer className="dark" />}
                        </FormGroup>
                        {forms != null || !setIsLoading ?
                            <>
                                <Label>
                                    Select Questionary
                                    <Select
                                        ref={formRef}
                                        className='col-md-12'
                                        styles={dropdownStile}
                                        options={forms}
                                        isMulti={true}
                                        required={true}
                                        isClearable
                                        isSearchable
                                        placeholder="Questionaries" />
                                </Label>
                                <Label>
                                    Due Date
                                    <DateTimePicker ref={dueDateRef} className="form-control" clockClassName={"hidden"} format="dd/MM/yyyy" closeWidgets={false} disableClock={true} value={value} onChange={setValue} required={true} />
                                </Label>
                            </>
                            : <LoaderContainer className="dark" />}

                        <input type="hidden" name="Id" defaultValue="" />

                        {/* <FormGroup row>
                                    <Label sm={2} for="phone">Phone:</Label>
                                    <Input sm={10} type="phone" name="phone" id="phone" defaultValue={editingContact.Phone} placeholder='phone' />
                                </FormGroup> */}
                        <Button disabled={sendEnabled}>Send</Button>
                    </Form>
                </Col>
            </Modal>
        </>
    )
}
export default QuestionarySendModal;