import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Link } from "react-router-dom";
import { Button, ButtonGroup, Col, Input, Row, UncontrolledTooltip } from 'reactstrap';
import Card from "../../components/ui/Card/Card";
import SupplierCounter from '../../components/ui/SupplierCounter/SupplierCounter';
import CustomerRatingScore from '../../components/ui/CustomerRatingScore/CustomerRatingScore';
import CustomerRatingNScore from '../../components/ui/CustomerRatingNScore/CustomerRatingNScore';
// import Tab from '../../components/ui/Tab/Tab';
import DataTable from '../../components/ui/Table/DataTable';
import SmallRating from '../../components/ui/SmallRating/SmallRating';
import DCSDoughnut from '../../components/ui/Charts/Doughnut/Doughnut';

import LoaderContainer from '../../components/ui/LoaderContainer/LoaderContainer';
// import { SeverityColors } from '../../components/ui/Helpers/Colors';
// import { useVulnerabilityContext } from '../../contexts/vulnerabilityContext';
import BusinessImpact from '../../components/ui/BusinessImpact/BusinessImpact';
import IconButton from '../../components/ui/IconButtons/IconButton';
import Modal from '../../components/ui/Modal/Modal';
import { useNavigate } from 'react-router-dom';
import SupplierFilters from '../../components/ui/Helpers/SupplierFilters';
import { DeleteSupplier, Suppliers } from '../../data/suppliers';
import QuestionarySendModal from '../../components/ui/QuestinarySendModal/QuestionarySendModal';
import QuestionaryCalculator from '../../utils/questionaryCalculator';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faEdit, faFolderPlus, faFileExcel, faTrash } from '@fortawesome/free-solid-svg-icons';
import HeatMap from '../../components/ui/heatmap/heatmap';
import RatingFormulaCalculator from '../../utils/ratingFormulaCalculator';
import { getRatings } from '../../data/administration';
import { useAuth } from '../../contexts/authContext';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import ExportExcel from '../../utils/ExportFunction';
import { PostureRatingSupplier, PostureRatingSuppliers } from '../../utils/ratingCalculator';
import { GetQuestionaries } from '../../data/questionary';
import DynamicTable from '../../components/ui/Table/Table';

const shouldComponentUpdate = () => true;

const Portfolio = (props) => {
    const navigate = useNavigate();
    // const [heatmapData, setHeatmapData] = useState([]);
    const [openFilters, setOpenFilters] = useState(false);
    const [deleteRow, setDeleteRow] = useState(null);
    const [openDelete, setOpenDelete] = useState(false);
    const [dataSource, setDataSource] = useState(null);
    const [sourceData, setSourceData] = useState(null);
    const FilterRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [suppliers, setSuppliers] = useState([]);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [generalRating, setGeneralRating] = useState(-1);
    const [questionariesSent, setQuestionariesSent] = useState(null);
    const [avgScore, setAvgRating] = useState(null);
    const [formula, setFormula] = useState();
    const [id, setId] = useState();
    const auth = useAuth();
    const searchRef = useRef();
    // const toggle = () => setTooltipOpen(!tooltipOpen);

    const OpenFilterModal = () => {
       
        setOpenFilters(true);
        //FilterRef.current.showModal();    
    }
    const OpenDeleteModal = (row) => {       
            setDeleteRow(row);
            setOpenDelete(true);        
    }
    useMemo(() => {
        setIsLoading(true);
        Suppliers(null, auth.user.ClientId).then((result) => {
            //console.log("intra si aici");
            setSourceData(result.data);
            setSuppliers(result.data.Rating.SupplierRatings);
            setDataSource(result.data.Rating.SupplierRatings);
            setIsLoading(false);
        });
        getRatings(auth.token, auth.user.ClientId).then(rez => {
            setFormula(rez.data);
        });
        GetQuestionaries(auth.user.ClientId).then(rez => {
            //console.log(rez);
            const qsent = rez.data.filter(x => x.u.Status == "Sent" || x.u.Status == "Responded");
            setQuestionariesSent(qsent);
        })

    }, [auth.token, auth.user.ClientId])
    useMemo(() => {
        if (suppliers.length > 0 && formula != undefined) {
            /// CalculateRating();
        }
    }, [suppliers, formula])
    const AddSupplier = () => {
        navigate("/portfolio/suppliers/add");
    }
    const DeleteSelectedSupplier = () => {
        console.log(deleteRef.current.value);
        if (deleteRef.current.value == "delete") {
            DeleteSupplier(deleteRow.ClientId, deleteRow.Id);
            setOpenDelete(false);
            Suppliers(null, auth.user.ClientId).then((result) => {
                //console.log("intra si aici");
                setSourceData(result.data);
                setSuppliers(result.data.Rating.SupplierRatings);
                setDataSource(result.data.Rating.SupplierRatings);
                setIsLoading(false);
            });
            getRatings(auth.token, auth.user.ClientId).then(rez => {
                setFormula(rez.data);
            });
            GetQuestionaries(auth.user.ClientId).then(rez => {
                //console.log(rez);
                const qsent = rez.data.filter(x => x.u.Status == "Sent" || x.u.Status == "Responded");
                setQuestionariesSent(qsent);
            })
        }
    }
    const Export = () => {
        let jsonArray = [];
        suppliers.forEach((instance, indexx, record) => {
            // console.log(record[indexx]);
            const r = PostureRatingSupplier(record[indexx], formula);

            var tmp = {
                "Name": record[indexx].SupplierName,
                "BusinessImpact": record[indexx].businessImpact,
                "QuestionaryRating": record[indexx].Questionaries.length > 0 && record[indexx].Questionaries[0].Status == "Approved" || record[indexx].Questionaries[0].Status == "Responded" ? QuestionaryCalculator(record[indexx].Questionaries[0]) : 0,
                "PostureRating": r[0].Rating.score == undefined ? 0 : r[0].PostureRating.toFixed(0),
                "Rating": record[indexx].Questionaries.filter(x => x.Status == "Approved").length > 0 ? Scores[r[0].Rating.rating].rating : Scores[0].rating,
                "DateCreated": record[indexx].DateCreated,
                "AssetCount": record[indexx].Assets.length
            }
            jsonArray.push(tmp);
        })
        ExportExcel(jsonArray, "Suppliers", "Portofolio.xlsx")
    }
    const Title = "Portfolio";

    let t = 0;
    const initialSupplierSort = [{ columnKey: 'id', direction: 'ASC' }];
    let heatmapdata = [];

    const columns = [
        {
            name: 'Name',
            Header: 'Supplier Name',
            accesor: 'Name',
            sortable: true,
            minWidth: 300,
            defaultFlex: 1,
            Cell: ({ row }) => {
                return <><Link className='no-markings' to={`/portfolio/suppliers/${row.original.Supplier.Id}`}>{row.original.Supplier.Name}</Link></>
            },
            render: ({ data }) => {
                return <><Link className='no-markings' to={`/portfolio/suppliers/${data.Supplier.Id}`}>{data.Supplier.Name}</Link></>
            },
            shouldComponentUpdate
        },
        {
            name: 'businessImpact',
            Header: "Business Impact",
            accesor: 'Supplier',
            sortable: true,
            Cell: ({ row }) => {
                return <><BusinessImpact impactLevel={row.original.Supplier.BusinessImpact.businessImpact} /></>
            },
            render: ({ data }) => {
                return <><BusinessImpact impactLevel={data.Supplier.BusinessImpact.businessImpact} /></>
            }
        },
        {
            name: "Questionaries",
            Header: "Questionary Rating",
            accesor: "QuestionaryRating",
            sortable: true,
            defaultFlex: 1,
            Cell: ({ row }) => {
                console.log(row);
                const qr = row.original.QuestionaryRating > -1 ? row.original.QuestionaryRating : 0;
                let isApproved = row.original.QuestionaryRating > -1 ? <span style={{
                    display: "inline-block",
                    paddingLeft:"10px"
                }}><FontAwesomeIcon color="orange" size="xl" icon={faExclamationCircle} id={"qrating-" + row.original.Supplier.Id } />
                    <UncontrolledTooltip
                        placement="bottom"
                        target={"qrating-" + row.original.Supplier.Id}
                    >
                        Questionary not yet Approved
                    </UncontrolledTooltip>
                </span> : "";
                return <><span style={{display:"inline-block"} }>                   
                   
                        {auth.user.RolePermission.Role.PermissionActions.filter(x => x.ActionP.Name == "Edit" && x.Permission.Name == "Suppliers").length > 0 ?
                            <React.Fragment key={"quesionary-" + row.original.Supplier.Id}>
                                <Link className='link-hover text-center' id={"ratingToolTip-" + row.original.Supplier.Id} onClick={() => setId(row.original.Supplier.Id)}><span>{qr.toFixed(0)}</span>{row.original.QuestionaryRating == -1 && row.original.Supplier.QuestionarySents != undefined ? isApproved : null}</Link>
                                {/* <Link className='white link-hover  text-center' to={`/portfolio/suppliers/${params.row.Id}`} id={"ratingToolTip-" + params.row.Id}></Link> */}
                                {row.original.QuestionaryRating == -1 || row.original.Rating.Rating == 0 ?
                                    <UncontrolledTooltip
                                        placement="bottom"
                                        target={'ratingToolTip-' + row.original.Supplier.Id}
                                    >
                                        Supplier Has not been sent a Questionary to be filled. Send a request
                                    </UncontrolledTooltip> : null}
                            </React.Fragment> : qr}
                        
                                      

                </span>{row.original.Supplier.QuestionarySents !== undefined && row.original.Supplier.QuestionarySents.filter(x => x.Status == "Responded").length > 0 ? isApproved : null} </> 
            },
            render: ({ data }) => {
                //console.log(data);
                const qr = data.QuestionaryRating > -1 ? data.QuestionaryRating : 0;
                let isApproved = data.QuestionaryRating > -1 ? <span style={{
                    display: "flex", width: "100%",
                    height: "100%",
                    flexWrap: "nowrap",
                    alignItems: "center",
                    justifyContent: "center"
                }}><FontAwesomeIcon color="orange" size="xl" icon={faExclamationCircle} />
                    <UncontrolledTooltip
                        placement="bottom"
                        target={"ratingToolTip-" + data.Supplier.Id}
                    >
                        Questionary not yet Approved
                    </UncontrolledTooltip>
                </span> : "";
                return <span>
                    {/* { data.Questionaries.length == 0 || data.Questionaries[0].Status == "Rejected" ?  */}
                    <>
                        {auth.user.RolePermission.Role.PermissionActions.filter(x => x.ActionP.Name == "Edit" && x.Permission.Name == "Suppliers").length > 0 ?
                            <React.Fragment key={"quesionary-" + data.Supplier.Id}>
                                <Link className='link-hover text-center' id={"ratingToolTip-" + data.Supplier.Id} onClick={() => setId(data.Supplier.Id)}><span>{qr.toFixed(0)}</span>{data.QuestionaryRating == -1 && data.Supplier.QuestionarySents != undefined ? isApproved : null}</Link>
                                {/* <Link className='white link-hover  text-center' to={`/portfolio/suppliers/${params.row.Id}`} id={"ratingToolTip-" + params.row.Id}></Link> */}
                                {data.QuestionaryRating == -1 || data.Rating.Rating == 0 ?
                                    <UncontrolledTooltip
                                        placement="bottom"
                                        target={'ratingToolTip-' + data.Supplier.Id}
                                    >
                                        Supplier Has not been sent a Questionary to be filled. Send a request
                                    </UncontrolledTooltip> : null}
                            </React.Fragment> : qr}
                    </>
                    {/* : <Link className='link-hover text-center' to={`/portfolio/suppliers/${data.Id}`} id={"ratingToolTip-" + data.Id}><span>{qr.toFixed(0)}</span>{isApproved}</Link> */}

                </span>
            }
        },
        {
            name: "Assets",
            Header: "Posture Rating",
            accesor: "PostureRating",
            sortable: true,
            defaultFlex: 1,
            type: 'number',
            headerCellClass: 'dcs-sort',
            Cell: ({ row }) => {
                row.original.PostureRating.toFixed(0)
                return <span>{row.original.PostureRating.toFixed(0)}</span>
            },
            render: ({ data }) => {
                // const r = PostureRatingSupplier(data, formula);
                data.PostureRating.toFixed(0)
                return <span>{data.PostureRating.toFixed(0)}</span>
            }
        },
        {
            name: 'rating',
            Header: 'Risk Rating',
            accesor: 'rating',
            sortable: true,
            defaultFlex: 1,
            type: 'number',
            headerCellClass: 'dcs-sort',
            Cell: ({ row }) => {
                const r = row.original.Rating.Rating;
                if (row.original.QuestionaryRating > -1) {
                    return <strong>
                        <SmallRating rating={r} />
                    </strong>
                } else
                    return <SmallRating rating={0} />
            },
            render: ({ data }) => {
                const r = data.Rating.Rating;
                if (data.QuestionaryRating > -1) {
                    return <strong>
                        <SmallRating rating={r} />
                    </strong>
                } else
                    return <SmallRating rating={0} />
            }
        },
        {
            name: "contacts",
            Header: "Contacts",
            accesor: 'Supplier',
            defaultFlex: 1,
            headerCellClass: 'dcs-sort',
            Cell: ({ row }) => {
                return row.original.Supplier.Contacts.length > 0 ?
                    <>
                        {auth.user.RolePermission.Role.PermissionActions.filter(x => x.ActionP.Name == "Edit" && x.Permission.Name == "Suppliers").length > 0 ?
                            <React.Fragment key={"contactsFragment-" + row.original.Supplier.Id}>
                                <UncontrolledTooltip
                                    placement="bottom"
                                    target={'contactLink-' + row.original.Supplier.Id}
                                >
                                    {row.original.Supplier.Contacts.map(x => {
                                        return <Row><Col md={12}>{x.Name}</Col></Row>
                                    })}
                                </UncontrolledTooltip>
                                <Link id={`contactLink-` + row.original.Supplier.Id} className='link-hover text-center' to={"/portfolio/edit/" + row.original.Supplier.Id}>{row.original.Supplier.Contacts.length} contact(s)</Link>
                            </React.Fragment> : `${row.original.Supplier.Contacts.length} contact(s)`}

                    </> : <>
                        <UncontrolledTooltip
                            placement="bottom"
                            target={'contact-' + row.original.Supplier.Id}
                        >
                            Add Contacts to this Supplier
                        </UncontrolledTooltip><Link className='link-hover text-center' to={"/portfolio/edit/" + row.original.Supplier.Id} id={"contact-" + row.original.Supplier.Id}>------</Link></>;
            },
            render: ({ data }) => {
                return data.Supplier.Contacts.length > 0 ?
                    <>
                        {auth.user.RolePermission.Role.PermissionActions.filter(x => x.ActionP.Name == "Edit" && x.Permission.Name == "Suppliers").length > 0 ?
                            <React.Fragment key={"contactsFragment-" + data.Supplier.Id}>
                                <UncontrolledTooltip
                                    placement="bottom"
                                    target={'contactLink-' + data.Supplier.Id}
                                >
                                    {data.Supplier.Contacts.map(x => {
                                        return <Row><Col md={12}>{x.Name}</Col></Row>
                                    })}
                                </UncontrolledTooltip>
                                <Link id={`contactLink-` + data.Supplier.Id} className='link-hover text-center' to={"/portfolio/edit/" + data.Supplier.Id}>{data.Supplier.Contacts.length} contact(s)</Link>
                            </React.Fragment> : `${data.Supplier.Contacts.length} contact(s)`}

                    </> : <>
                        <UncontrolledTooltip
                            placement="bottom"
                            target={'contact-' + data.Supplier.Id}
                        >
                            Add Contacts to this Supplier
                        </UncontrolledTooltip><Link className='link-hover text-center' to={"/portfolio/edit/" + data.Supplier.Id} id={"contact-" + data.Supplier.Id}>------</Link></>;
            }
        },
        {
            name: 'DateCreated',
            Header: 'Date Created',
            accesor: "Supplier",
            sortable: true,
            Cell: ({ row }) => {
                return new Date(row.original.Supplier.DateCreated).toLocaleDateString('en-US');
            },
            render: ({ data }) => {
                return new Date(data.Supplier.DateCreated).toLocaleDateString('en-US');
            }
        },
        {
            name: 'Actions',
            id: "Actions",
            Header: '',
            sortable: false,
            showColumnMenuTool: false,
            description: 'This column has a value getter and is not sortable.',
            Cell: ({ row }) => {
                return (
                    <>
                        <Row>
                            <ButtonGroup>
                                <Button className='btn DefaultButton' size="sm" disabled={auth.user.RolePermission.Role.PermissionActions.filter(x => x.ActionP.Name == "View" && x.Permission.Name == "Assets").length > 0 ? false : true} onClick={() => navigate(`/portfolio/suppliers/${row.original.Supplier.Id}/assets`)}>{row.original.AssetCount} Assets</Button>
                                <Button className='btn DefaultButton' size="sm" disabled={auth.user.RolePermission.Role.PermissionActions.filter(x => x.ActionP.Name == "Edit" && x.Permission.Name == "Suppliers").length > 0 ? false : true} onClick={() => navigate(`/portfolio/edit/${row.original.Supplier.Id}`)}><FontAwesomeIcon icon={faEdit} size="1x" /></Button>
                                <Button className='btn btn-danger' size="sm" disabled={auth.user.RolePermission.Role.PermissionActions.filter(x => x.ActionP.Name == "Delete" && x.Permission.Name == "Suppliers").length > 0 ? false : true} onClick={() => OpenDeleteModal(row.original.Supplier)}><FontAwesomeIcon icon={faTrash} size="1x" /></Button>
                            </ButtonGroup>
                        </Row>
                    </>
                )
            },
            render: ({ data }) => {
                return (
                    <>
                        <Row>
                            <Col md={12}>
                                <ButtonGroup>
                                    <Button className='btn DefaultButton' size="sm" disabled={auth.user.RolePermission.Role.PermissionActions.filter(x => x.ActionP.Name == "View" && x.Permission.Name == "Assets").length > 0 ? false : true} onClick={() => navigate(`/portfolio/suppliers/${data.Supplier.Id}/assets`)}>{data.AssetCount} Assets</Button>
                                    <Button className='btn DefaultButton' size="sm" disabled={auth.user.RolePermission.Role.PermissionActions.filter(x => x.ActionP.Name == "Edit" && x.Permission.Name == "Suppliers").length > 0 ? false : true} onClick={() => navigate(`/portfolio/edit/${data.Supplier.Id}`)}><FontAwesomeIcon icon={faEdit} size="1x" /></Button>
                                    <Button className='btn DefaultButton' size="sm" disabled={auth.user.RolePermission.Role.PermissionActions.filter(x => x.ActionP.Name == "Edit" && x.Permission.Name == "Suppliers").length > 0 ? false : true} onClick={() => navigate(`/portfolio/edit/${data.Supplier.Id}`)}><FontAwesomeIcon icon={faEdit} size="1x" /></Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </>
                )
            }
        }
    ];

    //Search
    const [gridRef, setGridRef] = useState(null);
    const [searchText, setSearchText] = useState('');
    const searchTextRef = useRef(searchText);
    searchTextRef.current = searchText;

    const render = ({ value }) => {
        const lowerSearchText = searchTextRef.current.toLowerCase();
        if (!lowerSearchText) {
            return value;
        }
        const str = value + ''; // get string value
        const v = str.toLowerCase(); // our search is case insensitive
        const index = v.indexOf(lowerSearchText);

        if (index === -1) {
            return value;
        }

        return [
            <span key="before">{str.slice(0, index)}</span>,
            <span key="match" style={{ background: 'yellow', fontWeight: 'bold' }}>
                {str.slice(index, index + lowerSearchText.length)}
            </span>,
            <span key="after">{str.slice(index + lowerSearchText.length)}</span>,
        ];
    };

    const onSearchChange = ({ target: { value } }) => {
        const visibleColumns = gridRef.current.visibleColumns;

        const lowerSearchText = value && value.toLowerCase();
        const newData = suppliers.filter((p) => {
            return visibleColumns.reduce((acc, col) => {
                // console.log(col.id);
                const v = (p.Supplier[col.id] + '').toLowerCase(); // get string value
                // console.log(p.Supplier);
                return acc || v.indexOf(lowerSearchText) != -1; // make the search case insensitive
            }, false);
        });

        setSearchText(value);
        setDataSource(newData);
    };
    const Scores = [
        { score: "N/A", rating: "N/A", color: "#979797" },
        { score: "A", rating: "GREAT", color: "#1BC000" },
        { score: "B", rating: "GOOD", color: "#02830F" },
        { score: "C", rating: "BAD", color: "#F97316" },
        { score: "D", rating: "CRITICAL", color: "#EF4444" },

    ];
    const CalculateRating = () => {
        //console.log("intra");
        let Rating = 0;
        let QuestionaryRating = -1;
        let suppliersReviewed = 0;
        const r = PostureRatingSuppliers(suppliers, formula);
        if (r != undefined) {
            if (r.QuestionaryRating > 0) {
                setAvgRating(Math.round(r.avgRating));
                setGeneralRating(Math.round(r.Rating));
            } else {
                setAvgRating(0);
                setGeneralRating(0);
            }
        } else {
            setAvgRating(0);
            setGeneralRating(0);
        }
    }



    const ShowHideQuestionaryModal = (show) => {
        setId(undefined);
    }
    const deleteRef = useRef(null);
    return (
        <>
            {openDelete ? <Modal ref={FilterRef} title={"Delete"} callBack={() => { setOpenDelete(false); }} >
                <Row>
                    <Col md={12}>
                        Are you shure you want to delete  {deleteRow!=null?deleteRow.Name:null}?<br />
                        Type in "delete" in the input below
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <input name="delConf" className="form-control" type="text" ref={deleteRef}/>
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <Button className="btn DefaultButton">Cancel</Button>
                        <Button className="btn btn-danger" onClick={() => { DeleteSelectedSupplier() }}>Delete</Button>
                    </Col>
                </Row>
            </Modal> : null}
            {
                openFilters ? <Modal ref={FilterRef} title={"Filters"} callBack={() => { setOpenFilters(false); }} >
                    <SupplierFilters />
                </Modal> : null
            }
            {id != undefined ?
                <QuestionarySendModal questionariesSent={questionariesSent} SupplierId={id} setShowModal={ShowHideQuestionaryModal} /> : null
            }
            <Row className="white">
                <Col xs md="12" className="headerText">
                    <h3 className="dcs-Title bold">{Title}</h3>

                    <ButtonGroup>
                        <Input type="text" className="searchInput" ref={searchRef} onChange={onSearchChange} placeholder="Search Suppliers..." />
                        <Button className='btn-primary' id="addSupplier" size="xl" disabled={auth.user.RolePermission.Role.PermissionActions.filter(x => x.ActionP.Name == "Edit" && x.Permission.Name == "Suppliers").length > 0 ? false : true} onClick={() => { navigate("/portfolio/add") }}><FontAwesomeIcon icon={faFolderPlus} /></Button>
                        <UncontrolledTooltip
                            placement="bottom"
                            target={"addSupplier"}
                        >
                            Add Supplier
                        </UncontrolledTooltip>
                        <Button className='btn-primary' id="exportportfolio" size="xl" disabled={auth.user.RolePermission.Role.PermissionActions.filter(x => x.ActionP.Name == "View" && x.Permission.Name == "Suppliers").length > 0 ? false : true} onClick={() => { Export() }}><FontAwesomeIcon icon={faFileExcel} /></Button>
                        <UncontrolledTooltip
                            placement="bottom"
                            target={"exportportfolio"}
                        >
                            Export portfolio
                        </UncontrolledTooltip>
                    </ButtonGroup>
                </Col>
            </Row>
            <Row>
                <Col md="8" xs="12" >
                    <Col>
                        <Card>
                            <Row className="customerGeneralInfo">
                                <SupplierCounter number={suppliers.length} percentage={100} />
                                <Col md={4} className='supplierCounter'>
                                    {sourceData != null ?
                                        <CustomerRatingScore gRating={sourceData != null ? Scores[sourceData.Rating.Rating].score : Scores[0].score} /> : null
                                    }
                                </Col>
                                {sourceData != null ?
                                    <CustomerRatingNScore rating={Math.round(sourceData.Rating.Score)} /> : <LoaderContainer />}
                            </Row>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Row>
                                <Col md="12">

                                </Col>
                                <Col md="12">
                                    {!isLoading && dataSource != null ?
                                        <DynamicTable handle={setGridRef} showTitle={false} id={"portfolio"} rowHeight={50} livePagination={true} defaultsortColumns={initialSupplierSort} showZebraRows={false} rowSelect={false} pagination={true} title="Suppliers" data={dataSource} showCellBorders={false} PageSize={10} columns={columns} /> : <LoaderContainer />}
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Col>
                <Col md="4" xs="12">
                    <Col>
                        <Card>
                            <h5 className='dcs-Title'>Supplier Rating Distribution</h5>
                            <Row>
                                <Col md="12">
                                    {!isLoading > 0 ?
                                        <DCSDoughnut supplierData={suppliers} /> : <LoaderContainer />}
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Card>
                        <h5 className='dcs-Title'>CyberPosture Risk HeatMap</h5>
                        {
                            suppliers != null ?
                                <HeatMap data={suppliers} /> : <LoaderContainer />
                        }

                    </Card>
                </Col>
            </Row>
        </>
    )
}
export default Portfolio;