import React, { useEffect, useState, useRef, Fragment } from 'react';
import ReactDOMServer from 'react-dom/server';

import { saveAs } from 'file-saver';
import { Page, Font, Text, View, Document, StyleSheet, PDFViewer, Image, pdf } from '@react-pdf/renderer';
import { Button, ButtonGroup, Col, Input, Row, UncontrolledTooltip } from 'reactstrap';
import { Registry, ReactFormGenerator } from 'react-form-builder2';
import logo from '../components/ui/reportPDF/dcslogo.png';
import parse from "html-react-parser";
import Robot from '../fonts/Roboto-Regular.ttf';
import RobotoBold from '../fonts/Roboto-Bold.ttf';
import RobotoItalic from '../fonts/Roboto-Italic.ttf';

Font.register({
    family: "Roboto",
    src: Robot
});
Font.register({
    family: "RobotoBold",
    fontStyle: 'bold',
    src: RobotoBold
});
Font.register({
    family: "RobotoItalic",
    src: RobotoItalic
});


const htmlStyles = StyleSheet.create({
    ul: {
        marginBottom: 5,
        paddingLeft: 10,
        fontFamily: "Roboto",
    },
    li: {
        marginBottom: 2,
        fontFamily: "Roboto",
    },
    p: {
        marginBottom: 5,
        fontFamily: "Roboto",
    },
    bold: {
        fontWeight: "bold",
        fontFamily: "Roboto",
    },
    text: {
        marginBottom: 5,
        fontFamily: "Roboto",
    },
});

// Create styles
const styles = StyleSheet.create({
    page: { fontSize: 11, paddingTop: 24, paddingLeft: 10, paddingRight: 20, lineHeight: 1.5, flexDirection: 'column' },
    spaceBetween: { flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', color: "#3E3E3E" },
    titleContainer: { flexDirection: 'row', marginTop: 24, marginBottom: 24, flexWrap: "wrap" },
    logo: { width: 90 },
    reportTitle: { fontSize: 16, textAlign: 'center', width: "100%", paddingBottom: 10 },
    reportView: { display: "flex", flexDirection: 'row', flexWrap: "wrap" },
    theader: { marginTop: 20, fontSize: 10, fontStyle: 'bold', paddingTop: 4, paddingLeft: 7, flex: 1, height: 20, backgroundColor: '#DEDEDE', borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1 },
    theader2: { flex: 2, borderRightWidth: 0, borderBottomWidth: 1 },
    tbody: { fontSize: 9, paddingTop: 4, paddingLeft: 7, flex: 1, borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1 },
    total: { fontSize: 9, paddingTop: 4, paddingLeft: 7, flex: 1.5, borderColor: 'whitesmoke', borderBottomWidth: 1 },
    tbody2: { flex: 2, borderRightWidth: 1, },
    section: {
        marginBottom: 15
    },
    container: {
        margin: 0,
        padding: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start"
    },
    text: {
        marginBottom: 10,
        paddingBottom: "5px",
        padding: 0,
        lineHeight: 1,
        fontSize: 10,
        width: "100%",
        borderBottom: "1px solid #d1d1d1",
        flexWrap: "wrap",
    },
    smallText: {
        marginBottom: 10,
        paddingBottom: "5px",
        padding: 0,
        lineHeight: 1,
        fontSize: 10,
        width: "100%",
        flexWrap: "wrap",
    },
    ul: {
        flex: 1,
        marginBottom: 0,
        paddingLeft: 5,
    },
    li: {

    },
    p: {
        marginBottom: 2,
    },
    bold: {
        fontWeight: "bold",
    },
    label: {
        fontWeight: "bold",
        flexDirection: 'row',
        marginBottom: 5,
        fontSize: 10,
        border: "1px solid red",
    },
    answer: {
        fontFamily: "RobotoBold",
        fontStyle: "bold"
    },
});
const renderLabel = (label) => {
    if (React.isValidElement(label)) {
        // Directly return the JSX element
        return label;
    }

    if (typeof label === "string") {
        // Parse and render the HTML string
        return renderHTML(label);
    }

    // Fallback for unsupported types
    console.warn("Unsupported label type:", label);
    return <Text>Unsupported Label</Text>;
};
const renderHTML = (node) => {
    if (typeof node === "string") {
        // If input is a raw HTML string, parse it
        return parse(node, {
            replace: (domNode) => renderHTML(domNode),
        });
    }

    if (!node) {
        return null;
    }

    // Handle parsed node types
    if (node.type === "text") {
        if (node.data.trim() !== "") {
            return <Text style={htmlStyles.p}>{node.data}</Text>; // Render plain text
        }
    }

    if (node.name === "p") {
        return <Text style={htmlStyles.p}>{node.children.map(renderHTML)}</Text>;
    }

    if (node.name === "ul") {
        return <View style={htmlStyles.ul}>{node.children.map(renderHTML)}</View>;
    }

    if (node.name === "li") {
        return <Text style={htmlStyles.li}>• {node.children.map(renderHTML)}</Text>;
    }

    if (node.name === "b" || node.name === "strong") {
        return <Text style={htmlStyles.bold}>{node.children.map(renderHTML)}</Text>;
    }

    // For unsupported tags, render their children
    if (node.children && Array.isArray(node.children)) {
        return node.children.map(renderHTML);
    }

    return null; // Ignore unsupported or empty nodes
};
const mergeFormAndAnswers = (form, answers) => {
    return form.map((field) => {
        const answer = answers.find((a) => a.id === field.id);
        let formattedAnswer = "";
        switch (field.element) {
            case "TextInput":
            case "TextArea":
                formattedAnswer = answer ? answer.value : "No answer";
                break;

            case "RadioButtons":
                if (answer) {
                    const selectedOption = field.options.find((opt) =>
                        answer.value.includes(opt.key)
                    );
                    formattedAnswer = selectedOption ? selectedOption.text : "No answer";
                } else {
                    formattedAnswer = "No answer";
                }
                break;
            case "Custome":
                if (answer) {

                    formattedAnswer = "True";
                } else {
                    formattedAnswer = "False";
                }
                break;
            case "Checkboxes":
                if (answer && Array.isArray(answer.value)) {
                    const selectedOptions = answer.value.map((key) =>
                        field.options.find((opt) => opt.key === key)
                    );
                    formattedAnswer = selectedOptions
                        .filter((opt) => opt) // Filter out nulls
                        .map((opt) => opt.text)
                        .join(", ");
                } else {
                    formattedAnswer = "No answer";
                }
                break;

            case "Dropdown":
                if (answer) {
                    const selectedOption = field.options.find(
                        (opt) => opt.key === answer.value
                    );
                    formattedAnswer = selectedOption ? selectedOption.text : "No answer";
                } else {
                    formattedAnswer = "No answer";
                }
                break;

            case "DatePicker":
                formattedAnswer = answer ? answer.value : "No date selected";
                break;

            default:
                formattedAnswer = "Unsupported field type";
        }

        return {
            label: field.label,
            answer: formattedAnswer,
        };
    });
};

const ExportQuestionaryAsPdf = async (questionary, supplier) => {
    /*const { questionary } = props;*/
    const form = JSON.parse(questionary.Questionary.Questionary);
    const answers = JSON.parse(questionary.Response);
    const mergedForm = mergeFormAndAnswers(form, answers);
    //const [form, setForm] = useState(null);
    //const [answers, setAnswers] = useState(null);
    //const [mergedForm, setMergedForm] = useState(null);
    //useEffect(() => {
    //    if (questionary !== undefined) {
    //        setForm(JSON.parse(questionary.Questionary.Questionary));
    //        setAnswers(JSON.parse(questionary.Response));
    //    }
    //}, [questionary]);
    //useEffect(() => {
    //    if (answers !== null && form !== null) {
    //        setMergedForm();
    //        //console.log(mergeFormAndAnswers(form, answers));
    //    }
    //}, [answers, form])
    const ReportHeader = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                <Image style={styles.logo} src={logo} />
                <Text style={styles.reportTitle}>Third Seer Report</Text>
            </View>
        </View>
    );
    /*const html = ReactDOMServer.renderToStaticMarkup(<ReactFormGenerator read_only={true} data={JSON.parse(questionary.Questionary.Questionary)} hide_actions={true} display_short={false} answer_data={JSON.parse(questionary.Response)} />);*/
    const Doc = (
        <Document>
            <Page size="A4" style={styles.page}>
                <ReportHeader />
                <View style={styles.titleContainer}>
                    <Text style={styles.reportTitle}>Questionary :{questionary.Questionary.Name}</Text>
                    <Text style={styles.smallText}>Supplier :{supplier.Name}</Text>
                    <Text style={styles.smallText}>Completed by :{supplier.Contacts.filter(x => x.Id == questionary.ContactId)[0].Name}</Text>
                </View>
                <View style={styles.container}>
                    {
                        mergedForm != null ?
                            mergedForm.map((field, index) => (
                                <View key={index} style={styles.text}>
                                    {renderLabel(field.label)}
                                    <Text style={styles.answer}>{field.answer}</Text>
                                </View>
                            )) : null}
                </View>
            </Page>
        </Document>
    )
    //return (
    //    <PDFViewer width={900} height={768} className='app'>
    //        <Document>
    //            <Page size="A4" style={styles.page}>
    //                <ReportHeader />
    //                <View style={styles.titleContainer}>
    //                    <Text style={styles.reportTitle}>Questionary :{questionary.Questionary.Name}</Text>
    //                </View>
    //                <View style={styles.container}>
    //                    {
    //                        mergedForm != null ?
    //                            mergedForm.map((field, index) => (
    //                                <View key={index} style={styles.text}>
    //                                    {renderLabel(field.label)}
    //                                    <Text style={styles.answer}>{field.answer}</Text>
    //                                </View>
    //                            )) : null}
    //                </View>



    //            </Page>
    //        </Document>
    //    </PDFViewer>
    //)
    const str = await pdf(Doc).toBlob();
    //console.log(str);
    const d = new Date().toJSON().slice(0, 10)

    //const blob = new Blob(str, {type: 'application/pdf' });
    saveAs(str, `${questionary.Questionary.Name}-${supplier.Name}-${d}.pdf`);

}
export default ExportQuestionaryAsPdf;