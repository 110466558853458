import React, { useEffect, useRef, useState } from 'react';
import "./AddEditSupplier.css";
import { Button, Col, Form, FormGroup, Input, InputGroup, Label, Row, Alert } from 'reactstrap';
import Card from '../../components/ui/Card/Card';
import LoaderContainer from '../../components/ui/LoaderContainer/LoaderContainer';
import Anaf from '../../data/anaf';
import IconButton from '../../components/ui/IconButtons/IconButton';
// import CustomerRatingScore from '../../components/ui/CustomerRatingScore/CustomerRatingScore';
import Select from 'react-select';
import DynamicTable from '../../components/ui/Table/Table';
import Modal from '../../components/ui/Modal/Modal';
import Countries from '../../data/countries';
import { Suppliers, SaveSupplier, HostSupplier, UpdateSupplier } from '../../data/suppliers';
import { useNavigate, useParams } from 'react-router-dom';
import BusinessImpactBig from '../../components/ui/BusinessImpactBig/BusinessImpactBig';
import DateTimePicker from 'react-datetime-picker';
import { ColorRing } from 'react-loader-spinner';
import { DeleteContactAPI, GetContactsAPI, SaveContactAPI, UpdateContactAPI } from '../../data/contacts';
import { useAuth } from '../../contexts/authContext';

const AddEditSupplier = (props) => {
    const InitialEditingContactState = {
        id: "",
        Name: "",
        Email: "",
        Phone: ""
    }
    const auth = useAuth();
    const [value, setValue] = useState(new Date().toISOString())
    //const [fmtValue, setFmtValue] = useState(undefined)
    const { SupplierId } = useParams();
    const [error, setError] = useState();
    const [department, setDepartment] = useState();
    const [isSaving, setIsSaving] = useState();
    const navigate = useNavigate();
    const [countries, setCountries] = useState([]);
    const [supplierData, setSupplierData] = useState();
    const [contacts, setContacts] = useState([]);
    const [editingContact, setEditingContact] = useState(InitialEditingContactState);
    const [detailsEdit, setDetailsEdit] = useState(false);
    const [supplierCountry, setSupplierCountry] = React.useState(0);
    const [showAddContact, setShowAddContact] = useState(false);
    const [loading, setLoading] = useState(false);
    const Title = SupplierId != undefined ? "Edit Supplier" : "Add Supplier";
    const [clientId, setClientId] = useState("");
    const searachRef = useRef(null);
    const domainRef = useRef(null);
    const addModalRef = useRef(null);
    const addContactFormRef = useRef(null);
    const CountryRef = useRef(null);
    const _detaliiClient = useRef(null);
    const _department = useRef(null);
    const _contractDate = useRef(null);
    //Departments dropdown init
    const Department = [
        { value: "0", label: "Marketing" },
        { value: "1", label: "R&D" },
        { value: "2", label: "Sales" },
        { value: "3", label: "Customer Success" },
        { value: "4", label: "Operations" },
        { value: "5", label: "HR" },
    ]
    const handleChange = (value, formattedValue) => {
        setValue(value)
        // setFmtValue(formattedValue)
    }
    useEffect(() => {
        Countries().then((result) => {
            const r = result.data.map((x) => { return { value: x.Id, label: x.Name }; })
            setCountries(r);
        })
        setClientId(auth.user.ClientId);
    }, []);
    useEffect(() => {
        if (SupplierId !== undefined && countries.length > 0) {
            setLoading(true);
            Suppliers(SupplierId, auth.user.ClientId).then(x => {
                const sup = {
                    date_generale: {
                        Id: x.data.NewSuppliers.Id,
                        denumire: x.data.NewSuppliers.Name,
                        cui: x.data.NewSuppliers.NationalIdentifier,
                        nrRegCom: x.data.NewSuppliers.RegistrationNumber,
                        SupplierRef: x.data.NewSuppliers.SupplierRef,
                        adresa: x.data.NewSuppliers.Address,
                        ClientId: x.data.NewSuppliers.ClientId,
                        CountryId: x.data.NewSuppliers.CountryId,
                        Assets: x.data.NewSuppliers.Assets,
                        Details: x.data.NewSuppliers.Details,
                        Department: x.data.NewSuppliers.Department,
                        ContractDate: x.data.NewSuppliers.ContractDate,
                        QuestionarySents: null,
                        businessImpact: x.data.NewSuppliers.BusinessImpact != undefined ? x.data.NewSuppliers.BusinessImpact : {
                            "businessImpact": 0,
                            "DateCreated": new Date()
                        }
                    }
                };
                //console.log(sup);
                setSupplierData(sup);
                //setContacts(rows);
                setValue(sup.date_generale.ContractDate);
                setDepartment(sup.date_generale.Department);
                //setSupplierCountry(x.data.CountryId);
                setValueProgrammatically(x.data.NewSuppliers.CountryId);
                // console.log(x.data.Contacts)
                setContacts(x.data.NewSuppliers.Contacts);
                //setContacts(x.Contacts);
                // GetContactsAPI(SupplierId).then(rez => {
                //     console.log(rez.data);
                //     setContacts(rez.data);
                //     setLoading(false);
                // })
                setLoading(false);
            })
        }
    }, [countries])

    const nameRef = useRef();
    const cuiRef = useRef();
    const nrRegComRef = useRef();
    const addressRef = useRef();

    //Get SupplierData
    const getSupplierData = async (e) => {
        const { key } = e;
        if (key === undefined || key === "Enter") {
            setLoading(true);
            setSupplierData(null);
            HostSupplier(searachRef.current.value).then(x => {
                if (x.data != "") {
                    x.data = JSON.parse(x.data);
                }
                if (x.data === undefined || x.data === "") {
                    Anaf(searachRef.current.value, (result) => {
                        if (result != undefined) {
                            result.date_generale.businessImpact = {
                                "businessImpact": 0,
                                "DateCreated": new Date()
                            };
                            setSupplierData(result);
                            setContacts(rows);
                            setLoading(false);
                            setValueProgrammatically(176);
                        } else {
                            console.log("intra")
                            const sup = {
                                date_generale: {
                                    denumire: <input type="text" ref={nameRef} placeholder="Company Name" name="denumire" className="form-control" />,
                                    cui: <input type="text" placeholder="Cui" ref={cuiRef} name="cui" className="form-control" />,
                                    nrRegCom: <input type="text" ref={nrRegComRef} placeholder="Registration Number" name="nrRegCom" className="form-control" />,
                                    adresa: <textarea name="adresa" ref={addressRef} placeholder="Address" className="form-control" />,
                                    businessImpact: {
                                        "businessImpact": 0,
                                        "DateCreated": new Date()
                                    }
                                }
                            }
                            console.log(sup);
                            setSupplierData(sup);
                            if (x.data.Supplier != undefined) {
                                setValueProgrammatically(x.data.Supplier.CountryId);
                            }
                        }
                    });
                } else {
                    const sup = {
                        date_generale: {
                            denumire: x.data.Name,
                            cui: x.data.NationalIdentifier,
                            nrRegCom: x.data.RegistrationNumber,
                            adresa: x.data.Address,
                            Assets: x.data.Assets != undefined ? x.data.Assets : [],
                            businessImpact: {
                                "businessImpact": 0,
                                "DateCreated": new Date()
                            }

                        }
                    }
                    setSupplierData(sup);
                    setValueProgrammatically(x.data.CountryId);

                }
            })
        }
    }
    //Set The Country after load
    const setValueProgrammatically = (id) => {
        const newValue = countries.find(option => option.value === id);
       // console.log(newValue);
        setSupplierCountry(newValue);
    };
       

    //Dropdown Styles
    const dropdownStile = {
        control: (styles) => ({ ...styles, backgroundColor: '#FFF' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isDisabled ? "#1861ac" : isSelected ? "#1861ac" : isFocused ? "#1861ac" : undefined,
                color: isDisabled ? undefined : isSelected ? "#FFF" : isFocused ? "#FFFFFF" : "#1861ac",
            }
        },
        placeholder: (styles) => ({ ...styles, color: "#999" }),
        singleValue: (styles, { data }) => ({ ...styles, backgroundColor: "#FFF", color: "#1D56A3" })
    }

    //Column init for Contacts
    const contactColumns = [
        {
            Header: 'Name',
            accessor: 'Name'
        },
        {
            Header: 'Email',
            accessor: 'Email'
        },
        {
            Header: ' ',
            Cell: ({ row }) => {
                return <div className="text-right">
                    <Button className="DefaultButton" onClick={() => EditContact(row.original)}>
                        Edit
                    </Button>
                    <Button color="danger" onClick={() => DeleteContact(row)}>
                        Delete
                    </Button>
                </div>
            }

        }
    ];

    //Show Add Contact popup
    const addContact = () => {
        setShowAddContact(true);
    }
    //Edit Contact popup
    const EditContact = (row) => {
        setEditingContact(row);
        setShowAddContact(true);
    }
    const Buttons = [
        <IconButton icon={"faUserPlus"} onClick={addContact}></IconButton>
    ]
    let rows = [
        // { id: 1, Name: "test", Email: "test@datacoresystems.ro", Phone: "+40000000" },
        // { id: 2, Name: "test", Email: "test@datacoresystems.ro", Phone: "+40000000" },
        // { id: 3, Name: "test", Email: "test@datacoresystems.ro", Phone: "+40000000" },
    ]

    //Delete contact
    const DeleteContact = (row) => {
        console.log(row);
        DeleteContactAPI(row.Id).then(rez => {
            const newContactsArray = contacts.map(element => {
                if (element.id != row.Id)
                    return element;
            });
            setContacts(newContactsArray);
        })


        //console.log(newContactsArray);

        // setEditingContact(InitialEditingContactState);
    }

    //Save Contact
    const SaveContact = (e) => {
        e.preventDefault();
        if (e.target.Id.value == "") {
            const contact = {
                Name: e.target.name.value,
                Email: e.target.email.value,
                Delete: false,
                SupplierId: SupplierId
            }
            SaveContactAPI(contact).then(rez => {
                if (contacts.length >= 1) {
                    if (editingContact.Name == "") {
                        contacts.unshift(contact);
                    } else {
                        contact.Id = rez.data.Id;
                        const newContactsArray = contacts.map(element => {
                            if (element.Id == contact.Id)
                                return contact;
                            else return element;
                        })
                        setContacts(newContactsArray);
                        setEditingContact(InitialEditingContactState);

                    }
                } else {
                    setContacts([contact]);
                }
                //console.log(contacts);
                setShowAddContact(false);
            });
        } else {
            const contact = {
                Id: e.target.Id.value,
                Name: e.target.name.value,
                Email: e.target.email.value,
                Delete: false,
                SupplierId: SupplierId
            }
            UpdateContactAPI(contact).then(rez => {
                if (editingContact.Name == "") {
                    contacts.unshift(contact);
                } else {
                    const newContactsArray = contacts.map(element => {

                        if (element.Id === contact.Id)
                            return contact;
                        else return element;
                    })
                    setContacts(newContactsArray);
                    setEditingContact(InitialEditingContactState);
                    //console.log(contacts);

                }
                setShowAddContact(false);
            });
        }

    }



    //Save Supplier
    const SaveSupplierCallback = () => {
        setIsSaving(true);
        if (nameRef.current != undefined) {
            supplierData.date_generale.denumire = nameRef.current.value;
        }
        if (cuiRef.current != undefined) {
            supplierData.date_generale.cui = cuiRef.current.value;
        }
        if (nrRegComRef.current != undefined) {
            supplierData.date_generale.nrRegCom = nrRegComRef.current.value;
        }
        if (addressRef.current != undefined) {
            supplierData.date_generale.adresa = addressRef.current.value;
        }
        if (supplierData.date_generale != undefined) {
            const supData = {
                Address: supplierData.date_generale.adresa,
                ClientId: clientId,
                CountryId: supplierCountry.value,
                Id: SupplierId,
                Name: supplierData.date_generale.denumire,
                NationalIdentifier: supplierData.date_generale.cui.toString(),
                RegistrationNumber: supplierData.date_generale.nrRegCom,
                Department: _department.current.getValue().length > 0 ? _department.current.getValue()[0].value : "",
                Details: _detaliiClient.current.value,
                SupplierRef: supplierData.date_generale.SupplierRef != undefined ? supplierData.date_generale.SupplierRef : null,
                ContractDate: value,
                QuestionarySents: [],
                BusinessImpact: supplierData.date_generale.businessImpact != undefined ? supplierData.date_generale.businessImpact : {
                    "businessImpact": 0,
                    "DateCreated": new Date()
                },
                Assets: [
                    {
                        Id: supplierData.date_generale.Assets != undefined ? supplierData.date_generale.Assets[0].Id : undefined,
                        Name: domainRef.current.value,
                        Ip: "",
                        Network: "",
                        WebPlatform: "",
                        Added: "Manual",
                        IsDefault: true,
                        DateCreated: new Date()
                    }
                ]
            }
            if (SupplierId == null) {
                SaveSupplier(supData).then(result => {
                    if (result.data.Error != null) {
                        setError(result.data.Error);
                    } else {
                        navigate("/portfolio");
                    }
                }).catch(e => {
                    // setError(e);
                    setIsSaving(false);
                })
            } else {
                UpdateSupplier(supData).then(result => {
                    if (result.data.Error != null) {
                        setError(result.data.Error);
                    } else {
                        navigate("/portfolio");
                    }
                }).catch(e => {
                    console.log(e);
                    // setError(e);
                    setIsSaving(false);
                });
            }
        } else {
            console.log(supplierData.date_generale);
        }
    }

    //Close Eror Alert
    const CloseAlert = () => {
        setError(null);
    }

    //Update Business Impact Callback
    const UpdateBusinessImpact = (businessImpact) => {
        const newData = supplierData;
        newData.date_generale.businessImpact.businessImpact = businessImpact;
        setSupplierData({ ...supplierData, newData });
    }

    const UpdateDepartment = () => {
        const d = supplierData;
        d.date_generale.Department = _department.current.value;
        setSupplierData({ ...supplierData, d });
    }
    // const UpdateDetails = () => {
    //     const d = supplierData;
    //     d.date_generale.Department = _detaliiClient.current.value;
    //     setSupplierData({ ...supplierData, d });
    // }
    return (
        <>
            {
                showAddContact ?
                    <Modal title="Add Contact" ref={addModalRef} callBack={setShowAddContact}>
                        <Col>
                            <Form className='row' ref={addContactFormRef} onSubmit={SaveContact}>
                                <FormGroup row>
                                    <Label sm={2} for="name">Name:</Label>
                                    <Input sm={10} type="text" name="name" id="name" defaultValue={editingContact.Name} placeholder='name' />
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={2} for="email">Email:</Label>
                                    <Input sm={10} type="email" name="email" id="email" defaultValue={editingContact.Email} placeholder='email@example.com' />
                                </FormGroup>
                                <input type="hidden" name="Id" defaultValue={editingContact.Id} />
                                {/* <FormGroup row>
                                    <Label sm={2} for="phone">Phone:</Label>
                                    <Input sm={10} type="phone" name="phone" id="phone" defaultValue={editingContact.Phone} placeholder='phone' />
                                </FormGroup> */}
                                <Button>Save</Button>
                            </Form>
                        </Col>
                    </Modal>
                    : null
            }

            {
                error ?
                    <Alert color="danger" toggle={CloseAlert}>{error}</Alert> : null
            }
            <Row>
                <Col md="12" xs="12">
                    <h3 className="dcs-Title bold">{Title}</h3>
                </Col>
            </Row>
            <Row className='justify-content-center' style={{ marginBottom: "40px" }}>
                <Col className="dcs-details-left" md={6}>
                    <Card>
                        {SupplierId == undefined ?
                            <Row className='thin'>
                                <Col>
                                    <Label>National Identifier</Label>
                                    <InputGroup>
                                        <Input type="text" name="cui" onKeyUp={getSupplierData} innerRef={searachRef} />
                                        <Button className="DefaultButton" onClick={getSupplierData}>
                                            Search
                                        </Button>
                                    </InputGroup>
                                </Col>
                            </Row>
                            :
                            <Row>
                                <Col>
                                    <h5 className="dcs-Title bold">Supplier Details</h5>
                                </Col>
                            </Row>}
                        {supplierData ?
                            <>
                                <Row className="thin">
                                    <Col md={6} className="bottomLine">
                                        <Row className="bottomLine smallSpace">
                                            <Col md={12}>
                                                Name:
                                            </Col>
                                            <Col>
                                                {supplierData.date_generale.denumire}
                                            </Col>
                                        </Row>

                                        <Row className="bottomLine smallSpace">
                                            <Col md={6} className="rightLine smallSpace">
                                                <Col md={12}>
                                                    National Identifier:
                                                </Col>
                                                <Col md={12}>
                                                    {supplierData.date_generale.cui}
                                                </Col>
                                            </Col>
                                            <Col md={6}>
                                                <Col md={12} className="right">
                                                    Registration No:
                                                </Col>
                                                <Col md={12} className="right">
                                                    {supplierData.date_generale.nrRegCom}
                                                </Col>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12} className="justfity smallSpace">
                                                Address: <span>{supplierData.date_generale.adresa}</span>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col md={6}>
                                        <Row>
                                            <Col md={6}>
                                                Details:
                                            </Col>
                                            <Col md={6} className="text-right">
                                                <IconButton icon="faPen" onClick={() => {
                                                    console.log("intra");
                                                    setDetailsEdit(!detailsEdit);
                                                    console.log(detailsEdit);
                                                }} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <textarea rows={4} cols={40} ref={_detaliiClient} className='form-control dcs-customerDetails' defaultValue={supplierData.date_generale.Details} readOnly={detailsEdit} ></textarea>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <label>
                                                Country:
                                                <Select
                                                    defaultValue={supplierCountry}
                                                    ref={CountryRef}
                                                    className='col-md-12'
                                                    styles={dropdownStile}
                                                    options={countries}
                                                    onChange={(choice) => setSupplierCountry(choice)}
                                                    isClearable
                                                    isSearchable
                                                    placeholder="Select Country" />
                                            </label>
                                        </Row>
                                        <Row>
                                            <FormGroup>
                                                <Label>
                                                    Domain
                                                </Label>
                                                <Input required innerRef={domainRef} type="text" placeholder='domain.com' defaultValue={supplierData.date_generale.Assets != undefined ? supplierData.date_generale.Assets[0].Name : ""} />
                                            </FormGroup>
                                        </Row>
                                    </Col>

                                </Row>
                                <Row className="thin">
                                    <Col>
                                        <Row><h5 className='dcs-Title bold'>Business Information</h5></Row>
                                        <Row>
                                            <Col className="test-center">
                                                <FormGroup>
                                                    <Label>Department</Label>
                                                    <Select
                                                        defaultValue={department}
                                                        styles={dropdownStile}
                                                        options={Department}
                                                        isClearable
                                                        isSearchable
                                                        ref={_department}
                                                        // onChange={UpdateDepartment}
                                                        placeholder="Select the department where the Supplier is activating" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="contract-datepicker">Contract Start Date</Label>
                                                    <DateTimePicker ref={_contractDate} className="form-control" clockClassName={"hidden"} format="dd/MM/yyyy" closeWidgets={false} disableClock={true} value={value} onChange={setValue} required={true} />
                                                    {/* <DateTimePicker ref={_contractDate} value={value} /> */}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {/* <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Notes</Label>
                                                    <Input placeholder='Explain the supplier purpose'  />
                                                </FormGroup>
                                            </Col>
                                        </Row> */}
                                    </Col>
                                </Row>
                            </>
                            : loading ? <LoaderContainer /> : null}

                    </Card>
                </Col>
                <Col md={4} className={`${supplierData && SupplierId != undefined ? "dcs-details-right" : "dcs-hide"}`}>
                    <Card>
                        <Row>
                            <Col md={10}>
                                <h5 className='dcs-Title bold'>Business Impact:</h5>
                                <p>The supplier's Business Impact affects general supplier rating</p>
                            </Col>
                            <Col md={12}>
                                {supplierData == undefined ?
                                    <LoaderContainer /> : <BusinessImpactBig value={supplierData.date_generale.businessImpact.businessImpact} callBack={UpdateBusinessImpact} />
                                }

                            </Col>
                        </Row>
                    </Card>
                    {SupplierId != undefined ?
                        <Card>
                            <Row>
                                <Col md={10}>
                                    Contacts:
                                </Col>
                                <Col md={1}>
                                    <IconButton icon={"faUserPlus"} disabled={supplierData ? false : true} onClick={addContact}></IconButton>
                                </Col>
                                <Col md={12}>
                                    {contacts.length > 0 ?
                                        <DynamicTable key={`table-` + contacts.length + 1} columns={contactColumns} rowHeight={40} data={contacts} showTitle={false} pagination={false} />
                                        : null}
                                </Col>
                            </Row>
                        </Card> : null}

                </Col>
            </Row>
            <Row className={"fixed-row-bottom m-0"}>
                <Col xl lg md sm={12} className='text-right'>
                    <Button className="btn pull-right btn-primary" size='lg' disabled={isSaving} onClick={() => { navigate("/portfolio") }}>Cancel</Button> <Button className="btn pull-right btn-primary" size='lg' onClick={SaveSupplierCallback}>
                        {
                            isSaving ? <ColorRing
                                visible={true}
                                height="20"
                                width="20"
                                ariaLabel="blocks-loading"
                                wrapperStyle={{}}
                                wrapperClass="blocks-wrapper"
                                colors={['#FFF', '#FFF', '#FFF', '#FFF', '#FFF']}
                            /> : "Save"
                        }
                    </Button>
                </Col>
            </Row>
        </>
    )
}
export default AddEditSupplier;